<template>
    <div>
      <header class="page-header bg-primary">
        <div class="logo-container">
          <img :src="Logo" alt="Start Waves" class="logo" />
        </div>
        <div class="flex">
            <i class="fa fa-handshake-o" aria-hidden="true"></i>
          <span class="page-title">
            Contact
          </span>
        </div>
        <button class="menu-toggle-btn" @click="toggleMenu">
          <i class="fa fa-bars fa-2x"></i>
        </button>
        <div class="menu" :class="{ 'menu-open': isMenuOpen }">
          <router-link to="/" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
            Home
          </router-link>
          <router-link to="/about-us" class="menu-item" @click="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
            About
          </router-link>
          <router-link to="/" class="menu-item disabled" @click.prevent="closeMenu"><i class="fa fa-btc" aria-hidden="true"></i>
            Contact
          </router-link>
          <!-- Add more menu items as needed -->
        </div>
      </header>
  
      <div class="page-container contact-container">
        <div class="contact-content">
          <div class="contact-card">
            <i class="fa fa-whatsapp fa-3x" aria-hidden="true"></i>
            <h3>WhatsApp</h3>
            <p>Send us a message on WhatsApp for instant support.</p>
            <p>+57 3203158442</p>
            <img v-bind:src="LogoSolo" alt="StartWaves Logo">
          </div>
  
          <div class="contact-card">
            <i class="fa fa-envelope fa-3x" aria-hidden="true"></i>
            <h3>Email</h3>
            <p>Contact us via email for inquiries and assistance.</p>
            <p>kadistusas@gmail.com</p>
          </div>
  
          <div class="contact-card">
            <i class="fa fa-mobile fa-3x" aria-hidden="true"></i>
            <h3>Mobile Number</h3>
            <p>Call or message us on our mobile number.</p>
            <p>+57 3245217436</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Logo from '@/assets/sw_logo_svg_3.svg';
  import LogoSolo from '@/assets/startwaves_logo_2024_svg_v4_256.svg';
  
  export default {
    name: 'Contact',
    data() {
      return {
        Logo: Logo,
        LogoSolo: LogoSolo,
        isMenuOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      closeMenu() {
        this.isMenuOpen = false;
      },
    },
  };
  </script>
  
  <style>
  /* Add any additional styles specific to the Contact page */
  .contact-container {
    padding: 20px;
  }
  
  .contact-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-card {
    text-align: center;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .contact-card i {
    margin-bottom: 10px;
  }
  
  .contact-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .contact-card p {
    font-size: 1rem;
    color: #666;
  }

  @media screen and (max-width: 640px) {
  .page-title {
    text-align: center; /* Alinear el texto al centro en dispositivos con ancho máximo de 768px */
    display: none; /* Asegurar que el texto tenga un comportamiento de bloque para ocupar todo el ancho disponible */
  }
}

  .menu-item.disabled {
    pointer-events: none; /* Evita que el enlace sea clickeable */
    color: #ccc; /* Cambia el color del texto para indicar visualmente que está desactivado */
    cursor: not-allowed; /* Cambia el cursor a "no permitido" para indicar que no se puede hacer clic */
  }

  .icon {
  display: none; /* Por defecto, oculta el icono */
  width: 24px;
  height: 24px;
  margin-right: 8px; /* Espacio entre el icono y el texto */
}

@media screen and (min-width: 640px) {
  .icon {
    display: none; /* Muestra el icono en modo responsive */
  }
}
  </style>
  